import {Autocomplete, Card, FormGroup, Grid, TextField} from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, {forwardRef, useEffect, useState} from 'react'
import CostPaymentComplete from './list/CostPaymentComplete'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {useRecoilState} from 'recoil'
import {CostAdminMonth} from 'atom/cost/CostWriteDetail'
import SelectForm from './list/select/SelectForm'
import {CostAdminYear} from 'atom/cost/CostWriteDetail'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SoftButton from 'components/SoftButton'
import {useQueries} from "react-query";
import allCommonCodeMap from "../../../api/commonCode/allCommonCodeMap";
import userAll from "../../../api/user/userAll";
import FormControlLabel from "@mui/material/FormControlLabel";

const AdminCostPage = () => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [month, setMonth] = useRecoilState(CostAdminMonth);
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [commonCodeMap, setCommonCodeMap] = useState(new Map()); // 공용코드 맵
    const userInfoList = []; // user 리스트
    const nameUserMap = new Map(); // 이름과 인덱스 매칭 맵
    const inputMap = new Map(); // 이름과 true 여부 맵

    const queries = useQueries([
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (result) => {
                setCommonCodeMap(result.data);
            }
        },
        {
            queryKey: ["userAll"],
            queryFn: () => userAll(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
    ]);

    if (queries[0].isSuccess && queries[1].isSuccess) {
        const userList = queries[1].data.data;
        for (let i = 0; i < userList?.length; i++) {
            const optionVal = `[${commonCodeMap[userList[i]?.memTeam]?.info}] ${userList[i]?.memName} ${commonCodeMap[userList[i]?.memRank]?.info}`
            userInfoList.push(optionVal);
            inputMap.set(optionVal, true);
            nameUserMap.set(optionVal, userList[i]?.memNo);
        }
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        // value 배열을 숫자로 변환하여 오름차순으로 정렬합니다.
        const sortedValues = value.map((item) => parseInt(item, 10)).sort((a, b) => a - b);

        // 순서대로 값을 저장하기 위해 sortedValues 배열을 참조하여 monthNames 배열에서 월을 가져옵니다.
        const selectedMonths = sortedValues.map((item) => monthNames[item - 1]);

        setMonth(selectedMonths);
    };

    const [open, setOpen] = React.useState(false);

    const handleToggle = (event) => {
        if (event.target.tagName !== 'SPAN') {
            setOpen(!open);
        }
    };

    // const handleClose = (event) => {
    //     console.log(event)
    //     event.stopPropagation();
    //     if (event.target.value) {
    //         setMonth(event.target.value);
    //     }
    //     setOpen(false);
    // };

    const currentYear = new Date().getFullYear(); // 현재 년도 가져오기
    const startYear = currentYear - 5; // 5년 전
    const endYear = currentYear + 5; // 5년 후
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    const [selectedYear, setSelectedYear] = useRecoilState(CostAdminYear);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const monthNames = [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월'
    ];

    // 보여줄 결재 문서 상태
    const [checkboxList, setCheckboxList] = useState(['AP001', 'AP002', 'AP003', 'AP004']);

    // 체크박스 값 리스트
    const checkValue = ["AP001", "AP002", "AP003", "AP004"];

    // 배열을 반으로 나누는 함수
    const splitArray = (arr) => {
        const middleIndex = Math.ceil(arr.length / 2);
        const firstHalf = arr.slice(0, middleIndex);
        const secondHalf = arr.slice(middleIndex);
        return [firstHalf, secondHalf];
    };

    const [firstColumnValues, secondColumnValues] = splitArray(checkValue);

    // 체크박스 핸들러
    const handleCheckboxChange = (label) => (event) => {
        const checked = event.target.checked;
        if (checked) {
            setCheckboxList((prevList) => [...prevList, label]);
        } else {
            setCheckboxList((prevList) => prevList.filter((item) => item !== label));
        }
    };

    // member 정확하게 선택 여부
    const [memberStatus, setMemberStatus] = useState(true);

    // 선택된 멤버
    const [checkIndex, setCheckIndex] = useState();

    /**
     * 멤버 인풋 채인지 핸들러
     * @param event
     * @param newInputValue
     */
    const handleMemberInputChange = (event, newInputValue) => {
        if (newInputValue !== '' || newInputValue !== null) {
            // 입력한 값과 옵션값이 맞는지 체크
            if (inputMap.get(newInputValue)) {
                setCheckIndex(nameUserMap.get(newInputValue));
                setMemberStatus(false);
            } else {
                setMemberStatus(true);
                setCheckIndex();
            }
        }
    };

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <Card
                    sx={{
                        backgroundColor: ({functions: {rgba}, palette: {white}}) =>
                            rgba(white.main, 0.8),
                        boxShadow: ({boxShadows: {navbarBoxShadow}}) => navbarBoxShadow,
                        position: "relative",
                        mt: 2,
                        mx: 0,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container p={1} alignItems="center" justifyContent="space-between">
                        <Grid item pb={1} xs={12} md={12} lg={12}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                비용처리 결재완료 문서
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container px={1} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid item xs={12} md={2.5} lg={2.5} pl={1} pt={2}>
                                    {[
                                        {values: firstColumnValues},
                                        {values: secondColumnValues},
                                    ].map(({values}) => (
                                        <FormGroup row>
                                            {values.map((value) => (
                                                <SoftBox mr={2}>
                                                    <FormControlLabel
                                                        key={value}
                                                        control={
                                                            <Checkbox
                                                                checked={checkboxList.includes(value)}
                                                                onChange={handleCheckboxChange(value)}
                                                            />
                                                        }
                                                        label={commonCodeMap[value]?.info}
                                                    />
                                                </SoftBox>
                                            ))}
                                        </FormGroup>
                                    ))}
                                </Grid>
                                <Grid item xs={12} md={3.5} lg={3.5} pt={2}>
                                    <Autocomplete
                                        renderInput={(params) => (
                                            <
                                                TextField
                                                {...params}
                                                label={memberStatus ? '멤버를 선택해주세요.' : ''}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        top: '-12px', // 플레이스홀더를 위로 이동시킵니다.
                                                    }
                                                }}
                                            />
                                        )}
                                        sx={{width:"90%"}}
                                        options={userInfoList}
                                        onInputChange={handleMemberInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6} md={2} lg={2} pt={2}>
                                    <SelectForm
                                        title="년도 선택"
                                        value={selectedYear}
                                        setClassification={setSelectedYear}
                                        list={years}
                                    />
                                </Grid>
                                <Grid item xs={6} md={2} lg={2} pt={2}>
                                    <FormControl sx={{width: '90%'}}>
                                        <InputLabel id="demo-multiple-checkbox-label" sx={{mt: -1.3}}>
                                            월 선택
                                        </InputLabel>
                                        <Select
                                            open={open}
                                            onOpen={handleToggle}
                                            onClick={handleToggle}
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={month}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Tag"/>}
                                            renderValue={(selected) => selected.join(", ")}
                                            MenuProps={MenuProps}
                                            sx={{cursor: "pointer"}}
                                        >
                                            {monthNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={month.indexOf(name) > -1}/>
                                                    <ListItemText primary={name}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} lg={2} display="flex" justifyContent="center" pt={2}>
                                    <SoftButton variant="gradient" color="info" onClick={() => setModalOpen(true)}>
                                        <PictureAsPdfIcon sx={{marginLeft: -1}}/>
                                        PDF문서
                                    </SoftButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <SoftBox mt={2} mb={2}>
                    <Card>
                        <CostPaymentComplete approvedCodeList={checkboxList} selectMember={checkIndex} month={month} year={selectedYear} modal={modalOpen}
                                             setModal={setModalOpen}></CostPaymentComplete>
                    </Card>
                </SoftBox>
            </DashboardLayout>
        </>
    )
}

export default AdminCostPage