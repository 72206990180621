import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 휴가 수정 (결재 완료된 휴가의 '수정 후 재요청' 시 휴가 수정)
 * @param body
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const ReEditVacation =(body)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationEdit?type=RE', body);
}
export default ReEditVacation;