import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftButton from "../../components/SoftButton";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {approverInsertAtomFamily} from "../../atom/approval/approver_referrer/approverInsertAtomFamily";
import {referrerInsertAtomFamily} from "../../atom/approval/approver_referrer/referrerInsertAtomFamily";
import {useEffect, useState} from "react";
import {toMap} from "../../utils/ToMap";
import cloneDeep from "lodash.clonedeep";
import {loginUser} from "../../atom/member/loginUser";
import VacationInputArea from "../vacationInsert/components/VacationInputArea";
import ApproverReferrer from "../vacationInsert/components/ApproverReferrer";
import {vacationInsertAtom} from "../../atom/vacation/vacationInsertAtom";
import {approverCountAtomFamily} from "../../atom/approval/approver_referrer/approverCountAtomFamily";
import {referrerCountAtomFamily} from "../../atom/approval/approver_referrer/referrerCountAtomFamily";
import {useMutation, useQueries} from "react-query";
import vacationView from "../../api/vacation/vacationView";
import Swal from "sweetalert2";
import EditVacation from "../../api/vacation/editVacation";
import Loading from "../../components/loading/Loading";
import {vacationPhoneValueAtom} from "../../atom/vacation/vacationPhoneValueAtom";
import {PhoneNumCheck} from "../../utils/PhoneNumCheck";
import {vacationReasonValueAtom} from "../../atom/vacation/vacationReasonValueAtom";
import {selectDaysAtomFamily} from "../../atom/calendar/selectDaysAtomFamily";
import {radioValueAtomFamily} from "../../atom/radioCheckBox/radioCheckBox";
import {vacationDayStateAtom} from "../../atom/vacation/vacationDayStateAtom";
import {vacationUseDayValueAtom} from "../../atom/vacation/vacationUseDayValueAtom";
import ReEditVacation from "api/vacation/reEditVacation";

/**
 * vacation Edit
 * @returns {JSX.Element}
 * @constructor
 */
function VacationEdit() {

    // navigate
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useSearchParams();
    const [isToReReply, setIsToReReply] = useState(false); // true - 결재 완료된 휴가의 수정페이지  | false - 결재 전 휴가의 수정페이지

    // 직접접근 방지
    useEffect(()=>{
        if(location.state === null) {
            Swal.fire({
                title: '잘못된 접근입니다.',
                icon: 'warning',
                backdrop: true
            })
                .then(()=>{navigate("/")});
        }
        
        if (params.get("type")) {
            if (params.get("type") === "RE") {
                setIsToReReply(true);
            }
        }
    },[])

    const vacNo = location.state?.vacNo;
    const approvalNo = location.state?.approvalNo;

    // 하위 컴포넌트에서 아톰패밀리 접근 키값
    const approverEditKey = "approverEditKey_" + vacNo;
    const referrerEditKey = "referrerEditKey_" + vacNo;
    const editKey = "editKey_" + vacNo;

    // 첫 마운트 시 이전에 있던 값들 리셋
    const vacationInfoReset = useResetRecoilState(vacationInsertAtom); // 휴가 정보
    const approverListReset = useResetRecoilState(approverInsertAtomFamily(approverEditKey)); // 결재자 리스트
    const referrerListReset = useResetRecoilState(referrerInsertAtomFamily(referrerEditKey)); // 참조자 리스트
    const approverCountReset = useResetRecoilState(approverCountAtomFamily(approverEditKey)); // 결재자 수
    const referrerCountReset = useResetRecoilState(referrerCountAtomFamily(referrerEditKey)); // 참조자 수
    const telReset = useResetRecoilState(vacationPhoneValueAtom); // 연락처
    const reasonReset = useResetRecoilState(vacationReasonValueAtom); // 휴가 사유
    const dateReset = useResetRecoilState(selectDaysAtomFamily(editKey+"_day")); // 휴가 일정
    const categoryReset = useResetRecoilState(radioValueAtomFamily(editKey+"_category")); // 카테고리
    const vacationDayErrorReset = useResetRecoilState(vacationDayStateAtom); // 휴가 사용일 에러
    const vacationUseDayReset = useResetRecoilState(vacationUseDayValueAtom); // 휴가 사용일


    // 리셋 함수
    const atomReset = () => {
        vacationInfoReset();
        approverListReset();
        referrerListReset();
        approverCountReset();
        referrerCountReset();
        telReset();
        reasonReset();
        dateReset();
        categoryReset();
        vacationDayErrorReset();
        vacationUseDayReset();
    }

    // 리셋 실행
    useEffect(()=>{
        atomReset();
    },[])

    // 전체 값
    const [vacationInfo, setVacationInfo] = useRecoilState(vacationInsertAtom);
    // login 정보
    const user = useRecoilValue(loginUser);
    // 결재자
    const [approverInfo, setApproverInfo] = useRecoilState(approverInsertAtomFamily(approverEditKey));
    const setApproverNum = useSetRecoilState(approverCountAtomFamily(approverEditKey));
    // 참조자
    const [referrerInfo,setReferrerInfo] = useRecoilState(referrerInsertAtomFamily(referrerEditKey));
    const setReferrerNum = useSetRecoilState(referrerCountAtomFamily(referrerEditKey));
    // 전화번호
    const vacationTelInfo = useRecoilValue(vacationPhoneValueAtom);
    // 휴가사유
    const vacReason = useRecoilValue(vacationReasonValueAtom);
    // 휴가 시작일 : start, 종료일 : end, 휴가일수 : day
    const vacationDateInfo = useRecoilValue(selectDaysAtomFamily(editKey+"_day"));
    // 휴가 구분 : value
    const vacationCategory = useRecoilValue(radioValueAtomFamily(editKey+"_category"));
    // 휴가 사용일 스테이트 : boolean
    const vacationDayError = useRecoilValue(vacationDayStateAtom);
    // 휴가 사용예정일
    const vacUseDay = useRecoilValue(vacationUseDayValueAtom);

    useEffect (() => {
        if(!vacReason) {
            return;
        }
        if (!params.get("type")) {
            if (vacReason.includes("[승인후수정건]")) {
                setIsToReReply(true);
            } else {
                setIsToReReply(false);
            }    
        }
    }, [vacReason])

    /**
     * '결재 요청 취소' -> '수정 후 재요청' 일 때는 '임시보관' 이 사용자에게 보이면 안 됨
     * return true -> 이미 결재가 완료된 '수정 후 재요청' 폼  false -> 결재 전인 '수정 폼'
     */
    const isWithdrawEdit = () => {
        if (!approverInfo || approverInfo == undefined) return false;   
        if (!approverInfo["approverList"] || approverInfo["approverList"] == undefined) return false;
        if (approverInfo["approverList"].length <= 0) return false;

        const approverDataList = approverInfo["approverList"];
        // console.log(Array.isArray(approverDataList)); // true
        let doCount = 0;
        approverDataList.map((approver) => {
            if (approver['approval_do'] == 1) doCount++;
        })
        
        if (approverDataList.length === doCount) return true;
        return false
    }

    useQueries(
        [
            // queries[0] : 휴가 정보
            {
                queryKey: ["vacationView", vacNo],
                queryFn: () => vacationView(parseInt(vacNo)),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                },
                onSuccess: (data) => {
                    const approverList = data.data.approverList;
                    const referrerList = data.data.referrerList;
                    for(let i=0; i<approverList.length; i++) {
                        approverList[i].approver_key = `${approverEditKey}_${(approverList[i].approver_level)+1}`;
                    }
                    for(let i=0; i<referrerList.length; i++) {
                        referrerList[i].referrer_key = `${referrerEditKey}_${i+1}`;
                    }
                    const approverInsert = toMap({...approverInfo});
                    const referrerInsert = toMap({...referrerInfo});
                    approverInsert.set("approverList", approverList);
                    referrerInsert.set("referrerList", referrerList);
                    setApproverInfo(Object.fromEntries(approverInsert));
                    setReferrerInfo(Object.fromEntries(referrerInsert));

                    setApproverNum({
                        count: data.data.approverList.length
                    });
                    setReferrerNum({
                        count: data.data.referrerList.length
                    });
                },
            },
        ]
    );

    // 임시보관
    function temporary() {
        let flag = true;
        const edit = toMap(vacationInfo);
        edit.set("vac_no", vacNo);
        edit.set("approval_no", approvalNo);
        edit.set("auth_no", user?.role);
        edit.set("mem_no", user?.id);
        edit.set("vac_team", user?.team);
        edit.set("vac_rank", user?.rank);
        const approverList = cloneDeep(approverInfo).approverList;
        edit.set("approverList", approverList)
        edit.set("referrerList", cloneDeep(referrerInfo).referrerList)

        if(vacationDateInfo.start === "" || vacationDateInfo.end === ""){
            flag=false;
            vacationErrorHandle("휴가 일정을 올바르게 선택하여 주십시오.");
        } else if(vacationDayError !== true){
            flag=false;
            vacationErrorHandle("사용예정 일수와 잔여 연차가 맞지 않습니다. 확인하여 주십시오.");
        } else if (vacationCategory.value === "" || vacationCategory.value === undefined) {
            flag=false;
            vacationErrorHandle("휴가 구분을 올바르게 선택하여 주십시오.");
        } else if (PhoneNumCheck(vacationTelInfo) === false) {
            flag=false;
            vacationErrorHandle("연락처를 올바르게 입력하여 주십시오.");
        } else if(vacUseDay === "" || vacUseDay === "0"){
            flag=false;
            vacationErrorHandle("사용예정 휴가일을 확인해주세요.");
        } else if (vacReason === "") {
            flag=false;
            vacationErrorHandle("휴가 사유를 입력하여 주십시오.");
        } else if (approverList !== null){
            if (approverList.length > 0 ) {
                edit.set("vac_approved", "AP005");
            } else {
                flag=false;
                vacationErrorHandle("결재자를 선택하여 주십시오.");
            }
        } else {
            flag=false;
            vacationErrorHandle("결재자를 선택하여 주십시오.");
        }

        if(flag) {
            if(vacationCategory.value === "VT003") {
                Swal.fire({
                    title: "1. 예비군 교육훈련 필증, 민방위 훈련 참가 필증을 경영지원부에 꼭 제출해주세요.\n2. 기타휴가는 상세사유를 자세하게 필히 작성해주세요.",
                    icon: 'warning',
                    showCancelButton: false,
                    backdrop:false
                }).then(() => {
                    edit.set("vac_tel",vacationTelInfo);
                    edit.set("vac_reason",vacReason);
                    edit.set("vac_start", vacationDateInfo.start);
                    edit.set("vac_end", vacationDateInfo.end);
                    edit.set("vac_days", vacUseDay);
                    edit.set("vac_category", vacationCategory.value);
                    addBody(Object.fromEntries(edit));
                })
            } else {
                edit.set("vac_tel",vacationTelInfo);
                edit.set("vac_reason",vacReason);
                edit.set("vac_start", vacationDateInfo.start);
                edit.set("vac_end", vacationDateInfo.end);
                edit.set("vac_days", vacUseDay);
                edit.set("vac_category", vacationCategory.value);
                addBody(Object.fromEntries(edit));
            }
        }
    }

    // 저장
    function save() {
        let flag = true;
        const edit = toMap(vacationInfo);
        const approverList = cloneDeep(approverInfo).approverList;
        edit.set("vac_no", vacNo);
        edit.set("approval_no", approvalNo);
        edit.set("auth_no", user?.role);
        edit.set("mem_no", user?.id);
        edit.set("mem_name", user?.name);
        edit.set("vac_team", user?.team);
        edit.set("vac_rank", user?.rank);
        edit.set("approverList", cloneDeep(approverInfo).approverList)
        edit.set("referrerList", cloneDeep(referrerInfo).referrerList)

        if(vacationDateInfo.start === "" || vacationDateInfo.end === ""){
            flag=false;
            vacationErrorHandle("휴가 일정을 올바르게 선택하여 주십시오.");
        } else if(vacationDayError !== true){
            flag=false;
            vacationErrorHandle("사용예정 일수와 잔여 연차가 맞지 않습니다. 확인하여 주십시오.");
        } else if (vacationCategory.value === "" || vacationCategory.value === undefined) {
            flag=false;
            vacationErrorHandle("휴가 구분을 올바르게 선택하여 주십시오.");
        } else if (PhoneNumCheck(vacationTelInfo) === false) {
            flag=false;
            vacationErrorHandle("연락처를 올바르게 입력하여 주십시오.");
        } else if(vacUseDay === "" || vacUseDay === "0"){
            flag=false;
            vacationErrorHandle("사용예정 휴가일을 확인해주세요.");
        } else if (vacReason === "") {
            flag=false;
            vacationErrorHandle("휴가 사유를 입력하여 주십시오.");
        } else if (approverList !== null){
            if (approverList.length > 0 ) {
                edit.set("vac_approved", "AP001");
            } else {
                flag=false;
                vacationErrorHandle("결재자를 선택하여 주십시오.");
            }
        } else {
            flag=false;
            vacationErrorHandle("결재자를 선택하여 주십시오.");
        }

        if(flag) {
            if(vacationCategory.value === "VT003") {
                Swal.fire({
                    title: "1. 예비군 교육훈련 필증, 민방위 훈련 참가 필증을 경영지원부에 꼭 제출해주세요.\n2. 기타휴가는 상세사유를 자세하게 필히 작성해주세요.",
                    icon: 'warning',
                    showCancelButton: false,
                    backdrop:false
                }).then(() => {
                    edit.set("vac_tel",vacationTelInfo);
                    edit.set("vac_reason",vacReason);
                    edit.set("vac_start", vacationDateInfo.start);
                    edit.set("vac_end", vacationDateInfo.end);
                    edit.set("vac_days", vacUseDay);
                    edit.set("vac_category", vacationCategory.value);
                    addBody(Object.fromEntries(edit));
                })
            } else {
                edit.set("vac_tel",vacationTelInfo);
                edit.set("vac_reason",vacReason);
                edit.set("vac_start", vacationDateInfo.start);
                edit.set("vac_end", vacationDateInfo.end);
                edit.set("vac_days", vacUseDay);
                edit.set("vac_category", vacationCategory.value);
                addBody(Object.fromEntries(edit));
            }
        }
    }

    // 휴가 입력
    const { mutate: addBody, isLoading} = useMutation(
        !isToReReply ? EditVacation : ReEditVacation, {
        onSuccess: (data) => {
            // 리셋
            atomReset();
            navigate("/vacation/view",{state:parseInt(vacNo)});
        },
        onError: () => {
            handleEditError();
        }
    });

    if(isLoading) {
        return (
            <Loading/>
        )
    }


    /**
     * 휴가 수정 에러 핸들
     */
    const handleEditError = () => {
        Swal.fire({
            title: '휴가 수정 중 에러가 발생했습니다. ',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '휴가메인페이지',
            backdrop:false

        }).then((result) => {
            if (result.isConfirmed) {
                // 리셋
                atomReset();
            } else {
                // 리셋
                atomReset();
                navigate('/vacation');
            }
        })
    }

    /**
     * 휴가 error 핸들
     * @param value : String error내용
     */
    const vacationErrorHandle = (value) => {
        Swal.fire({
            title: `${value}`,
            icon: 'warning',
            showCancelButton: false,
            backdrop:false

        })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={3}>
                {/* 휴가 입력 영역*/}
                <VacationInputArea keyName={editKey} vacNo={vacNo}/>
                {/* 결재 참조 라인*/}
                <ApproverReferrer approverKey={approverEditKey} referrerKey={referrerEditKey} docNo={vacNo}/>
                <Grid container spacing={3} mt={0.5} mb={0.5}>
                    <Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" alignItems="center">
                        {!isWithdrawEdit ? 
                        <>
                            <SoftBox>
                                <SoftButton variant="gradient" color="info" onClick={temporary}>
                                    <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                    &nbsp;임시저장
                                </SoftButton>
                            </SoftBox>&nbsp;&nbsp;&nbsp;
                        </> : <></>}
                        <SoftBox>
                            <SoftButton variant="gradient" color="success" onClick={save}>
                                <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                &nbsp;문서상신
                            </SoftButton>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default VacationEdit;
